<template>
  <Details
    title="Quext user"
    base-path="quextUsers.index"
  >
    <template v-slot:default="detailsProps">
      <CalendarEventCreateForm
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />      
    </template>
  </Details>  
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import CalendarEventCreateForm from '@/components/calendar/events/CreateForm';
import Details from '@/components/auth/details/Details';
import ModalNavigation from '@/mixins/ModalNavigation';

export default {
  mixins: [AuthMixin, ModalNavigation],
  components: {
    CalendarEventCreateForm,
    Details    
  },
}
</script>
