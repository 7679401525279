<template>
 <loader :loading="loading" :backdrop="true" />
  <Form :submit="handleSubmit" :initialValues="initialValues"  @change.self="handleFormChange" ref="calendarForm">
    <div class="form-narrow">
      <div class="form-row">
        <SelectInput
          name="activityType"
          label="Activity Type"
          :options="activityTypes"
          :editMode="!readonly"
          :validate="required"
        />
        <TextField
          name="firstName"
          label="First Name"
          type="text"
          :validate="required"
          autocomplete="off"
          :editMode="!readonly"
        />
        <TextField
          name="lastName"
          label="Last Name"
          type="text"
          autocomplete="off"
          :editMode="!readonly"
          :validate="required"
        />
      </div>
      <div class="form-row">
        <DateInput
          label="Activity Date"
          name="activityDate"
          :in-community-timezone="false"
        />   
        <TextField
          autocomplete="off"
          name="prospectEmail"
          label="Prospect Email"
          type="text"
          :editMode="!readonly"
          :validate="required"
        /> 
         <TextField
          autocomplete="off"
          name="prospectPhone"
          label="Prospect Phone"
          type="text"
          :editMode="!readonly"
          :validate="required"
        />        
      </div>
      <div class="form-row">
        <SelectInput
          name="activitySource"
          label="Activity Source"
          :options="activitySources"
          :editMode="!readonly"
          :validate="required"
        />
        <SelectInput
          name="startTime"
          label="Start Time"
          :options="availableTimes"
          :validate="required"
        />
        <div class="form-col"></div>
      </div>    
      <ModalFooter :footer="footer" :tertiary="cancel" />
    </div>
  </Form>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import SelectInput from "@/components/form/SelectInput";
import TextField from "@/components/form/TextField";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import DateInput from "@/components/form/DateInput";
import NotifyMixin from "@/mixins/NotifyMixin";
import Form from "@/components/form/Form";
import ModalNavigation from '@/mixins/ModalNavigation';
import moment from 'moment-timezone';

import ModalFooter from "@/components/ui/modals/ModalFooter";
import { mapGetters } from "vuex";

export default {
  name: "CalendarEventCreateForm",
  components: {
    ModalFooter,
    Form,
    TextField,
    SelectInput,
    DateInput,
  },
  mixins: [AuthMixin, ValidatorMixin, NotifyMixin, ModalNavigation],
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close"],
  data: function () {
    return {
      activityTypes: [
        {
          key: 'in_person_tour',
          value: "In person tour"
        },
        {
          key: 'call_back',
          value: "Callback",
        },
        {
          key: 'follow_up',
          value: "Follow up"
        },
      ],
      activitySources: [
        {
          key: 'digital_human',
          value: "Digital Human"
        },
        {
          key: 'websites',
          value: "Websites",
        },
        {
          key: 'guest_cards',
          value: "Guest Cards"
        },
        {
          key: 'manual',
          value: "Manual",
        },
        {
          key: 'other',
          value: "Other"
        },        
      ],
      roles: [],
      loading: false,
      availableTimes: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/profile",
    }),
    editMode() {
      return Boolean(this.initialValues.profileId);
    },
    readonly() {
      return Boolean(this.initialValues.deletedAt);
    },
    footer() {
      if (this.readonly) {
        return {
          tertiaryButton: "back",
        };
      }
      return {
        primaryButton: "save",
        tertiaryButton: "cancel",
      };
    },
  },
  methods: {
    convert24to12HourFormat(time, addClock){
      const timeArray = time.split(":");
      let hours = timeArray[0];
      let minutes = timeArray[1];
      let clock = " am";

      if(hours > 12){
        hours-=12;
        clock = " pm";
      }

      if(addClock){
        return `${hours}:${minutes}${addClock}`;
      }else{
        return `${hours}:${minutes}${clock}`;
      }

    },
    handleFormChange(state) {
      if(state.values?.activityDate && state.values?.activityType) {
        this.getAvailableTimes(state.values.activityDate, state.values.activityType);
      }
      if(state.values.startTime && (!state.values?.activityDate || !state.values?.activityType)){
        this.availableTimes = [];
      }
    },
    getAvailableTimes(activityDate, activityType) {
      this.loading = true;
      this.$calendarDataProvider
      .get('calendarGetCommunityAvailableTimeSlotsByDate', {
        community_id: this.community?.id,
        start_date: moment(activityDate).format('YYYY-MM-DD'),
        end_date:  moment(activityDate).format('YYYY-MM-DD'),
        appointment: activityType.toUpperCase(),
      })
      .then((res) => {
        if(!res)
          this.availableTimes = []
        if(res.available_times.length === 0)
          this.availableTimes = []
        this.availableTimes = res.available_times.map(time => {
          const timeFormatted = this.convert24to12HourFormat(time.slice(time.indexOf(' ') + 1));
          return { key: time, value: timeFormatted }
        })
      })
      .catch((err) => this.notifyError(err.message))
      .finally(() => this.loading = false);
    },
    handleSubmit(values) {
      this.loading = true;
      this.$calendarDataProvider
        .create('calendarCreateAppointments', {
            data: {
              community_id: this.community?.id,
              customer_id: this.profile?.customerId,
              activity_source_name: values.activitySource?.toUpperCase(),
              appointment_type_name: values.activityType?.toUpperCase(),
              desired_number_of_bedrooms: 1,
              max_budget: 1500,
              notes: "No notes",
              move_in_date: moment(values.activityDate).format('YYYY-MM-DD hh:mm:ss'),
              start_time: values.startTime,
              guest: {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.prospectEmail,
                phone_number: values.prospectPhone
              }
            }
        })
        .then(() => {
          this.$refs.calendarForm.restart();
          this.notifySuccess('Event created');
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.redirect('calendar_events.index')
    },
  },
};
</script>
